import React, {useState} from 'react';
import './Assistant.css';
import { observer } from 'mobx-react';
import Sidebar from '../../components/sidebar/index.js';
import AssistantAi from '../../components/assistant/index.js'
import {Helmet} from "react-helmet";
const Assistant = observer(() => {
    const [holdBlock, setholdBlock] = useState(true);
    const [datasegment, setDatasegment] = useState(1);
    const changemenu = () =>{
        setholdBlock(false)
    }
    window.onbeforeunload = () => {
        setholdBlock(true)
    }
    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    return (
        <>
            {
                langmenu=="de"?
                <Helmet>
                    <title>Bind Genius — Vorhersage von Nutzerverhalten</title>
                    <meta name="description" content="Bind Genius — eine No-Code-Lösung für maschinelles Lernen. Entscheidungsfindung auf Basis einer tiefgehenden Analyse historischer Ereignisse und Prognosen." />
                </Helmet>
                :
                <Helmet>
                    <title>Bind Genius — Customer Behavior Prediction</title>
                    <meta name="description" content="Bind Genius — a no-code machine learning solution. Decision-making based on in-depth analysis of historical events and forecasting." />
                </Helmet>
            }
            <Sidebar/>
            <div className="assistant">
                <div className='body_assistant'>
                    <AssistantAi/>
                </div>
            </div>
       
        </>
    );
})

export default Assistant;

