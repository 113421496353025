import React, {useState, useEffect} from 'react';
import AttachmentBox from "./AttachmentBoxrefresh";
import { observer } from 'mobx-react'
import './index.css';
import { changepopuprefreshDatasetcsv, deletepopupdataset, changepopuprefreshDatasetnum} from "../../stores/GlobalState";
import Domen from "../../stores/Domen";
import Loading from '../common/loading';
import lang from "../../lang"
const PopupRefreshDatasetCSV = observer(({id, deleteIdnum}) =>{
    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)
    const [disable, setDisable]= useState(false)
    const [attachments, setAttachments] = useState([]);
    const [err, setErr] = useState(false);
    const [errname, setErrname] = useState(false);
    const [errextra, setExtra] = useState(false);
    const [errmissing, setMissing] = useState(false);
    const [load, setLoad]= useState(0)
    const [textloading, setTextloading] = useState("Please wait<br><span>Refreshed in progress</span>");

    const refresh = async() =>{
        if(attachments[0]!=undefined){
            var token = localStorage.getItem("token");
            setDisable(true)
            const formData = new FormData();
            formData.append('file', attachments[0].file);
            setLoad(1)

            localStorage.setItem("countactivetime", Date.now());
            fetch(Domen.url + `dataset/csv/`+id+`/refresh`, {
                method: 'POST',
                headers: {
                    'Authorization': token,
                },
                body: formData,
            })
            .then((response) => {
                return new Promise((resolve) => response.json()
                  .then((json) => resolve({
                    status: response.status,
                    ok: response.ok,
                    json,
                  })));
            })
            .then(({ status, json, ok }) => {
                const message = json.message;
                let color = 'black';
                switch (status) {
                  case 502:
                    setLoad(0)
                    setErr(true)
                    setErrname(json.detail)
                    break;
                 case 503:
                    setLoad(0)
                    setErr(true)
                    setErrname(json.detail)
                    break;
                  case 401:
                    setLoad(0)
                    setErr(true)
                    setErrname(json.detail)
                    break;
                  case 400:
                    console.log(json.detail)
                    setErr(true)
                    setErrname(json.detail)
                    setLoad(0)
                    var t = "Extra: ";
                    json.extra.extra.map((n, id)=>{
                        id<5?
                        t = t + n + ", "
                        :
                        <></>
                    })
                    json.extra.extra.length>5?
                        t = t.slice(0, -2)+'...'
                        :
                        t = t.slice(0, -2)

                    setExtra(t)
                    t = "Missing: ";
                    json.extra.missing.map((n, id)=>{
                        id<5?
                        t = t + n + ", "
                        :
                        <></>
                    })
                    json.extra.missing.length>5?
                        t = t.slice(0, -2)+'...'
                        :
                        t = t.slice(0, -2)

                    setMissing(t)
                    break;
                  case 201:
                  case 200:
                    setErr(false)
                    setLoad(0)
                    changepopuprefreshDatasetcsv()
                    changepopuprefreshDatasetnum(id);
                    setDisable(false)
                    setTimeout(function(){
                        changepopuprefreshDatasetnum(0)
                    }, 5000)
                    break;
                  
            }})

        }
    }

    useEffect(() => { 
        refresh()
        
    }, [attachments]);

    
   
    return (
        <div className="popupInfofon refreshpopup refcsv">
            <div className="popupInfo deleteProduct" style={{position: "fixed"}}>
                <div className='top-element'>
                    {/* <div className='separator'></div> */}
                    <div></div>
                    <div className='buttonspopup' >
                        <div className='buttonexit' onClick={changepopuprefreshDatasetcsv}>
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.7891 5.27441L6.24236 18.181" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M6.06055 5.45312L18.9671 17.9998" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
               
                <div className='form-element formrefresh'>
                    {
                        load!=0?
                        <Loading text = {textloading}/>
                        :
                        <></>
                    }
                    {
                        load!=0?
                        <></>
                        :
                        err?
                        <>
                            <span className='subtitle'>Something went wrong</span>
                            <div className='titlePopupDelete trepop'>
                                Your dataset was not refreshed
                            </div>
                            <span className='errorname'>{errname}</span>
                            <span className='errorextra'>
                                {errextra}
                               
                            </span>
                            <span className='errormissing'>
                                {errmissing}
                                
                            </span>
                        </>
                        :
                        <>
                            <div className='titlePopupDelete' dangerouslySetInnerHTML={{ __html: langv?.dataset?.popuprefreshdataset?.text1+"<span> "+deleteIdnum+"</span>" }}>
                            </div>
                            <div className='buttonsPopupDelete ' style={{justifyContent:"center"}}>
                                <div className='buttonPopupDelete add_refresh' >
                                    <AttachmentBox {...{ attachments, setAttachments }} />
                                </div>
                                
                            </div>
                        </>
                    }


                    
                </div>
                            
            </div>
        </div>
    );
});

export default PopupRefreshDatasetCSV;
