import React, {useState} from 'react';
import { observer } from 'mobx-react'
import './index.css';
import { changepopuprefreshDataset, deletepopupdataset, changepopuprefreshDatasetnum} from "../../stores/GlobalState";
import Domen from "../../stores/Domen";
import Loading from '../common/loading';
import lang from "../../lang"
const PopupRefreshDataset = observer(({id, deleteIdnum}) =>{
    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)
    const [disable, setDisable]= useState(false)
    const [err, setErr] = useState(false);
    const [errname, setErrname] = useState(false);
    const [errextra, setExtra] = useState(false);
    const [errmissing, setMissing] = useState(false);
    const [load, setLoad]= useState(0)
    const [textloading, setTextloading] = useState("Please wait<br><span>Refreshed in progress</span>");
    
    const refreshproductline = async (id) => {
        setLoad(1)
        var token = localStorage.getItem("token");
        setDisable(true)
        localStorage.setItem("countactivetime", Date.now());
        fetch(Domen.url + `dataset/bigQuery/`+id+`/refresh`, {
            method: 'POST',
            headers: {
                'Authorization': token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
        .then((response) => {
            return new Promise((resolve) => response.json()
              .then((json) => resolve({
                status: response.status,
                ok: response.ok,
                json,
              })));
        })
        .then(({ status, json, ok }) => {
            const message = json.message;
            let color = 'black';
            switch (status) {
             case 500:
                setLoad(0)
                setErr(true)
                setErrname(json.detail)
                break;
              case 502:
                setLoad(0)
                setErr(true)
                setErrname(json.detail)
                break;
             case 503:
                setLoad(0)
                setErr(true)
                setErrname(json.detail)
                break;
              case 401:
                setLoad(0)
                setErr(true)
                setErrname(json.detail)
                break;
              case 400:
                console.log(json.detail)
                setErr(true)
                setErrname(json.detail)
                setLoad(0)
                var t = "Extra: ";
                json.extra.extra.map((n, id)=>{
                    id<5?
                    t = t + n + ", "
                    :
                    <></>
                })
                json.extra.extra.length>5?
                    t = t.slice(0, -2)+'...'
                    :
                    t = t.slice(0, -2)

                setExtra(t)
                t = "Missing: ";
                json.extra.missing.map((n, id)=>{
                    id<5?
                    t = t + n + ", "
                    :
                    <></>
                })
                json.extra.missing.length>5?
                    t = t.slice(0, -2)+'...'
                    :
                    t = t.slice(0, -2)

                setMissing(t)
                break;
              case 201:
              case 200:
                setErr(false)
                setLoad(0)
                changepopuprefreshDataset()
                changepopuprefreshDatasetnum(id);
                setDisable(false)
                setTimeout(function(){
                    changepopuprefreshDatasetnum(0)
                }, 5000)
                break;
              
        }})









        
        
    };
    const refreshproductall = async () => {
        setLoad(1)
        var token = localStorage.getItem("token");
        setDisable(true)
        localStorage.setItem("countactivetime", Date.now());
        fetch(Domen.url + `dataset/bigQuery/refreshAll`, {
            method: 'POST',
            headers: {
                'Authorization': token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
        .then((response) => {
            return new Promise((resolve) => response.json()
              .then((json) => resolve({
                status: response.status,
                ok: response.ok,
                json,
              })));
        })
        .then(({ status, json, ok }) => {
            const message = json.message;
            let color = 'black';
            switch (status) {
            case 500:
                setLoad(0)
                setErr(true)
                setErrname(json.detail)
                break;
              case 201:
              case 200:
                setErr(false)
                setLoad(0)
                changepopuprefreshDataset()
                //changepopuprefreshDatasetnum(id);
                setDisable(false)
                setTimeout(function(){
                    //changepopuprefreshDatasetnum(0)
                }, 5000)
                break;
              
        }})
    };

    return (
        <div className="popupInfofon refreshpopup">
            <div className="popupInfo deleteProduct" style={{position: "fixed"}}>
                <div className='top-element'>
                    {/* <div className='separator'></div> */}
                    <div></div>
                    <div className='buttonspopup'>
                        <div className='buttonexit' onClick={changepopuprefreshDataset}>
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.7891 5.27441L6.24236 18.181" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M6.06055 5.45312L18.9671 17.9998" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
                {
                    id==0?
                    <div className='form-element formrefresh'>
                        {
                            load!=0?
                            <Loading text = {textloading}/>
                            :
                            <></>
                        }
                        {
                            load!=0?
                            <></>
                            :
                            err?
                            <>
                                <span className='subtitle'>Something went wrong</span>
                                <div className='titlePopupDelete trepop'>
                                    Your dataset was not refreshed
                                </div>
                                <span className='errorname'>{errname}</span>
                                <span className='errorextra'>
                                    {errextra}
                                
                                </span>
                                <span className='errormissing'>
                                    {errmissing}
                                    
                                </span>
                            </>
                            :
                            <>
                                <div className='titlePopupDelete' dangerouslySetInnerHTML={{ __html: langv?.dataset?.popuprefreshdataset?.text1+"<span> all datasets</span>" }}>
                                
                                </div>
                                <div className='buttonsPopupDelete'>
                                    <div className='buttonPopupDelete' onClick={changepopuprefreshDataset}>
                                        {langv?.dataset?.popupdeletedataset?.text2}
                                    </div>
                                    <div className='buttonPopupDelete add_refresh' onClick={() => refreshproductall()}>
                                        {langv?.dataset?.popupdeletedataset?.text3}
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    :

                
                    <div className='form-element formrefresh'>
                        {
                            load!=0?
                            <Loading text = {textloading}/>
                            :
                            <></>
                        }
                        {
                            load!=0?
                            <></>
                            :
                            err?
                            <>
                                <span className='subtitle'>Something went wrong</span>
                                <div className='titlePopupDelete trepop'>
                                    Your dataset was not refreshed
                                </div>
                                <span className='errorname'>{errname}</span>
                                <span className='errorextra'>
                                    {errextra}
                                
                                </span>
                                <span className='errormissing'>
                                    {errmissing}
                                    
                                </span>
                            </>
                            :
                            <>
                                <div className='titlePopupDelete' dangerouslySetInnerHTML={{ __html: langv?.dataset?.popuprefreshdataset?.text1+"<span>"+ deleteIdnum+"</span>" }}>
                                
                                </div>
                                <div className='buttonsPopupDelete'>
                                    <div className='buttonPopupDelete' onClick={changepopuprefreshDataset}>
                                        {langv?.dataset?.popupdeletedataset?.text2}
                                    </div>
                                    {
                                        disable==false?
                                        <div className='buttonPopupDelete add_refresh' onClick={() => refreshproductline(id)}>
                                            {langv?.dataset?.popupdeletedataset?.text3}
                                        </div>
                                        :
                                        <div className='buttonPopupDelete add_refresh' style={{background:"#ccc", cursor: "default"}}>
                                            {langv?.dataset?.popupdeletedataset?.text3}
                                        </div>
                                    }
                                    
                                </div>
                            </>
                        }
                    </div>
                }               
            </div>
        </div>
    );
});

export default PopupRefreshDataset;
