import React, {useState, useEffect} from 'react';
import { observer } from 'mobx-react'
import AttachmentBox from "./AttachmentBox";
import AttachmentBoxAnaliticsbig from "./AttachmentBoxAnaliticsbig";
import TableExelfile from "./tableExcel";
import './index.css';
import Papa from "papaparse";
import lang from "../../lang"
import GlobalState, {changepopupaddAttachment} from "../../stores/GlobalState";
import Domen from "../../stores/Domen";
import Loading from '../common/loading';
import LoadingCompleted from '../common/loading_completed';
import LoadingFail from '../common/loading_fail';

const PopupInfo = observer(() =>{
    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)
    const [attachments, setAttachments] = useState([]);
    const [nameDatasetprew, setnameDatasetprew] = useState("");
    const [nameDataset, setnameDataset] = useState("");

    const [textloading, setTextloading] = useState("The Dataset is stored<br><span>Please wait...</span>");
    const [textloadingcompleated, setTextloadingcompleated] = useState("Dataset uploaded");



    const [objectid, setObjectid] = useState("");
    const [dataid, setDataid] = useState("");
    const [statehead, setStatehead] = useState(1);
    const [file, setFile] = useState();
    const [load, setLoad]= useState(3)
    const [save, setSave] = useState(false);
    const [timedata, setTimedata] = useState(false);

    const addDataset = async()=>{
        if(GlobalState.activemodeldatasetprewbigquery.length>0)
            
            {
                var token = localStorage.getItem("token");
                
                if(timedata)
                {
                    var product = {
                        table: GlobalState.tablebigquery,
                        name: nameDataset,
                        objectId: objectid,
                        credentials: GlobalState.credentialbigquery,
                    };
                }
                else
                {
                    var product = {
                        table: GlobalState.tablebigquery,
                        name: nameDataset,
                        objectId: objectid,
                        credentials: GlobalState.credentialbigquery,
                        dateColumnName: dataid,
                        tag:["Training"]
                    };
                }
                setTextloadingcompleated("Uploaded");
                localStorage.setItem("countactivetime", Date.now());
                const response = await fetch( Domen.url + `dataset/bigQuery/connect`, {
                    method: 'POST',
                    headers: {
                      'Authorization': token,
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(product),
                });
                if(response.status>"199"&&response.status<400)
                {
                    const data = await response.json();
                    setSave(true);
                    setLoad(2);
                    
                    setTimeout(function(){
                        //changepopupaddAttachment();
                        //setLoad(3);
                        //window.location.href = window.location.href
                    }, 3000)
                }
                
        }
        else{
            var token = localStorage.getItem("token");
            const formData = new FormData();
            formData.append('file', attachments[0].file);
            if(timedata)
                {
                   
                }
                else
                {
                    formData.append('tag', "Training");
                }
            
            if(dataid!="")
            {
                localStorage.setItem("countactivetime", Date.now());
                fetch( Domen.url + `dataset/csv/upload?object_id=`+objectid+'&name='+nameDataset+'&date_column_name='+dataid, {
                    method: 'POST',
                    headers: {
                        'Authorization': token,
                    },
                    body: formData,
                })
                .then(response => {
                    setLoad(2);
                    setTimeout(function(){
                        //changepopupaddAttachment();
                        //window.location.href = window.location.href
                    }, 3000)
                })
                .catch(error => {
                    console.error('There was an error with the fetch operation:', error);
                });
            }
            else{
                localStorage.setItem("countactivetime", Date.now());
                fetch( Domen.url + `dataset/csv/upload?object_id=`+objectid+'&name='+nameDataset, {
                    method: 'POST',
                    headers: {
                        'Authorization': token,
                    },
                    body: formData,
                })
                .then(response => {
                    setLoad(2);
                    setTimeout(function(){
                        //changepopupaddAttachment();
                        //window.location.href = window.location.href
                    }, 100)
                })
                .catch(error => {
                    console.error('There was an error with the fetch operation:', error);
                });
            }
            

            // 
        }
    }

    const changesave = () =>{
        setSave(true);
        setLoad(1)
        if(nameDataset!='')
        {

            addDataset();
            
        }        
    }

    const changepopupaddAttachment0 = () =>
    {
        window.location.href = window.location.href
        changepopupaddAttachment()
    }
    

    return (
        <div className="popupInfofon">
            <div className="popupInfo addwork attach zerascif" >
                <div className='top-element'>
                    <div className='namepopup'>
                    {langv?.dataset?.popupadddataset?.text9}
                    </div>
                    {/* <div className='separator'></div> */}
                    <div></div>
                    <div className='buttonspopup'>
                        <div className={nameDataset!=""&&objectid!=""&&(dataid!=""||timedata)&&load==3?'buttonsave active':'buttonsave'} onClick={()=>changesave()}>{langv?.dataset?.popupadddataset?.text14}</div>
                        <div className='buttonexit' onClick={changepopupaddAttachment0}>
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.7891 5.27441L6.24236 18.181" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M6.06055 5.45312L18.9671 17.9998" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className='stage_atach'>
                        
                    <div className={attachments[0]?.file||GlobalState.activemodeldatasetprewbigquery.length>0?'buton_stage inactive':'buton_stage active'}>
                    {
                        GlobalState.ispopupaddAttachmentShowbigdata==2?
                        langv?.dataset?.popupadddataset?.text10:
                        langv?.dataset?.popupadddataset?.text12
                    }
                    </div>
                    <div className={attachments[0]?.file||GlobalState.activemodeldatasetprewbigquery.length>0?'buton_stage active':'buton_stage'}>
                    {langv?.dataset?.popupadddataset?.text11}
                    </div>
                
                </div>
                {
                    load==1?
                    <Loading text = {textloading}/>
                    :
                    load==2?
                    <LoadingCompleted text = {textloadingcompleated}/>
                    :   
                    load==4?
                    <LoadingFail text = {textloadingcompleated}/>
                    :
                    <></>
                }
                {
                    load==3?
                    <>
                        
                        <div className={GlobalState.ispopupaddAttachmentShowbigdata==2?'adddataset-element bigdata':'adddataset-element'}>
                            {GlobalState.ispopupaddAttachmentShowbigdata==2 ? attachments[0]?.file||GlobalState.activemodeldatasetprewbigquery.length>0?<></>:
                                load>2?
                                    <input
                                        id="website"
                                        type="text"
                                        placeholder={langv?.dataset?.popupadddataset?.text13}
                                        className="pop-input datasetname"
                                        value={nameDatasetprew}
                                        style={{marginBottom: "24px"}}
                                        onChange={(event) => setnameDatasetprew(event.target.value)}
                                    />
                                    :
                                    <></>
                                :
                                <></>
                            }
                            {
                            attachments[0]?.file||GlobalState.activemodeldatasetprewbigquery.length>0?
                            <TableExelfile {...{ attachments, save, timedata, setTimedata, nameDataset, setnameDataset, objectid, setObjectid, dataid, setDataid, setLoad }}/>
                            :
                            GlobalState.ispopupaddAttachmentShowbigdata==1 ?
                            <AttachmentBox {...{ attachments, setAttachments, setLoad }} />
                            :
                            <AttachmentBoxAnaliticsbig {...{ attachments, setAttachments, setStatehead, nameDatasetprew, load, setLoad }} />
                            }
                            
                        </div>
                    </>
                    :
                    <></>
                }
            </div>
        </div>
    );
});

export default PopupInfo;
