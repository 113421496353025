import React, { useState, useContext } from "react";
import { AccountContext } from "./Account";
import { observer } from 'mobx-react';
import lang from "../../lang"
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import UserPool from './UserPool';
import GlobalState from "../../stores/GlobalState";
import { use } from "react";

const NewPassword = observer(() => {
  const [code, setCode] = useState("");
  const [typepass, setTypepass] = useState(0);
  const [typepass1, setTypepass1] = useState(0);    
  const [password001, setPassword001] = useState("");
  const [password2, setPassword2] = useState("");
  const [error, setError] = useState("");
  const [error2, setError2] = useState("");
  const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
  const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)
 

  const onSubmit = (event) => {
    event.preventDefault();
    if(password2==password001)
    {
      setError2("")
      var userData = {Username: GlobalState.emailuser, Pool: UserPool};
      const user = new CognitoUser(userData);
      const er = (a) =>{
        var t = a+"";
        setError(t.split(":")[1])
      }
      user.confirmPassword(code, password001, {onSuccess: () => location.href="/login", onFailure: (err) => er(err)})
    }
    else
    {
      setError2("Passwords do not match")
    }
  };
  const changepass = () =>{
    if(typepass===0)
    {
        setTypepass(1)
    }
    if(typepass===1)
    {
        setTypepass(0)
    } 
  }
  const changepass1 = () =>{
    if(typepass1===0)
    {
        setTypepass1(1)
    }
    if(typepass1===1)
    {
        setTypepass1(0)
    } 
  }

  return (
    <>
      <div className='central_img'></div>
      <div className='left_img'></div>
      <div className="login-container forgot newpassword">
          <form onSubmit={onSubmit}>
              <h1>Create a <span>new password</span></h1>
              <p>Enter the verification code sent to your email and set a strong new password to protect your account.</p>
              <label htmlFor="email">Code</label>
              <input
                  id="email"
                  className="auth-input"
                  type="text"
                  placeholder="Enter your code"
                  value={code}
                  onChange={(event) => setCode(event.target.value)}
              />
              {error && <div className="error-message">{error}</div>} {}

              <label htmlFor="password1">New password</label>
              <input
                  id="password1"
                  type={typepass===0?"password":"text"}
                  placeholder="Enter your password"
                  className="auth-input"
                  value={password001}
                  autocomplete="new-password"
                  onChange={(event) => setPassword001(event.target.value)}
              />
              <span class="password-toggle-icon" onClick={changepass} >
                {typepass===1?
                    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.6">
                            <path d="M13.0004 9.93555L15.6537 11.4675V14.5313L13.0004 16.0632L10.347 14.5313V11.4675L13.0004 9.93555Z" stroke="#020203"/>
                            <path d="M7 19L0.999999 13L7 7C10.3133 3.68667 15.6867 3.68667 19 7L25 13L19 19C15.6867 22.3133 10.3133 22.3133 7 19Z" stroke="#020203"/>
                            <line x1="0.646447" y1="24.6464" x2="24.6464" y2="0.646445" stroke="black"/>
                        </g>
                    </svg>
                    :
                    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.6">
                            <path d="M13.0013 8.93604L15.6547 10.4679V13.5318L13.0013 15.0637L10.348 13.5318V10.4679L13.0013 8.93604Z" stroke="#020203"/>
                            <path d="M7 18L0.999999 12L7 6C10.3133 2.68667 15.6867 2.68667 19 6L25 12L19 18C15.6867 21.3133 10.3133 21.3133 7 18Z" stroke="#020203"/>
                        </g>
                    </svg>
                }
              </span>


              <label htmlFor="password2">Confirm new password</label>
              <input
                  id="password2"
                  type={typepass1===0?"password":"text"}
                  placeholder="Enter your password"
                  className="auth-input"
                  value={password2}
                  onChange={(event) => setPassword2(event.target.value)}
              />
               <span class="password-toggle-icon" onClick={changepass1} >
                {typepass1===1?
                    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.6">
                            <path d="M13.0004 9.93555L15.6537 11.4675V14.5313L13.0004 16.0632L10.347 14.5313V11.4675L13.0004 9.93555Z" stroke="#020203"/>
                            <path d="M7 19L0.999999 13L7 7C10.3133 3.68667 15.6867 3.68667 19 7L25 13L19 19C15.6867 22.3133 10.3133 22.3133 7 19Z" stroke="#020203"/>
                            <line x1="0.646447" y1="24.6464" x2="24.6464" y2="0.646445" stroke="black"/>
                        </g>
                    </svg>
                    :
                    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.6">
                            <path d="M13.0013 8.93604L15.6547 10.4679V13.5318L13.0013 15.0637L10.348 13.5318V10.4679L13.0013 8.93604Z" stroke="#020203"/>
                            <path d="M7 18L0.999999 12L7 6C10.3133 2.68667 15.6867 2.68667 19 6L25 12L19 18C15.6867 21.3133 10.3133 21.3133 7 18Z" stroke="#020203"/>
                        </g>
                    </svg>
                }
              </span>
              {error2 && <div className="error-message">{error2}</div>} {}
             
             <button type="submit">Reset Password</button>
             <a href="/login" className="comebacklogin">Return to Sign In</a>
          </form>
      </div>
      <div className='right_img'></div>
    </>
    
  );
});
export default NewPassword;