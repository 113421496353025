import React, {useState, useEffect} from 'react';
import './CampaignsPage.css';
import { observer } from 'mobx-react'
import Sidebar from '../../components/sidebar/index.js';
import logoBig from '../../assets/logo-big.svg'
import Compaings from '../../components/compaings/index.js'
import Messages from '../../components/compaings/messages.js'
import GlobalState from "../../stores/GlobalState.js";
import PopupMicrosegment from '../../components/compaings/popupMicrosegment.js'
import PopupDeleteCompany from '../../components/compaings/popupDeleteCompany'
import PopupDeleteMessage from '../../components/compaings/popupDeleteMessage'
import Groupsettings from '../../components/groupsettings'
import PopupInfo from '../../components/groupsettings/popupUpdateInfo.js'
import PopupAddProduct from '../../components/groupsettings/popupAddProducts'
import PopupChangeProduct from '../../components/groupsettings/popupChangeProducts'
import PopupDeleteProduct from '../../components/groupsettings/popupDeleteProduct'
import PopupAddNewMessage from '../../components/compaings/popupAddNewMessage'
import PopupSaveNewMessage from '../../components/compaings/popupSaveNewMessage'
import Beta from '../../components/Beta/index.js';
import UserPool from '../../components/autorize/UserPool';
import {Helmet} from "react-helmet";


const Campaigns= observer(() => {
    const [holdBlock, setholdBlock] = useState(true);
    const [datasegment, setDatasegment] = useState(0);
    const [companyname, setCompanyname] = useState("");
    const [user, setUser]=useState("");
    const changemenu = () =>{
        setholdBlock(false)
    }
    window.onbeforeunload = () => {
        setholdBlock(true)
    }
    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")

    const token = localStorage.getItem("token");
    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const cognitoUser = UserPool.getCurrentUser();
            setUser(cognitoUser.username)
        } 
    }, []);


    return (
        <>
            {
                langmenu=="de"?
                <Helmet>
                    <title>Bind Genius — Kampagnenerstellung und Erstellung von personalisierten Inhalten</title>
                    <meta name="description" content="Erstellung von Kampagnen mit Bind Genius. Generierung von personalisiertem Content unter Berücksichtigung der Unternehmensspezifika, seiner Produkte und Angebote für jeden einzelnen Kunden." />
                </Helmet>
                :
                <Helmet>
                    <title>Bind Genius — Campaign Creation and Personalized Content Generation</title>
                    <meta name="description" content="Campaign creation with Bind Genius. Generating personalized content tailored to the user's company activities, its products, and offers for each customer." />
                </Helmet>
            }
            <Sidebar/>
            {
                user=="admin"||user=="test_team_2"||user=="test_team_3"||user=="test"||user=="m.bahmat"||user=="k.zaporozhchenko"||user=="m.tsvyd"||user=="DemoBG"?
                
                <>
                <div className="campaign hold">
                    <div className='body_segment'>
                        {GlobalState.numbersubmenu===1?datasegment==0?<Compaings {...{ setDatasegment, datasegment,setCompanyname  }} />:<Messages {...{ setDatasegment, datasegment, companyname }}/>:<Groupsettings/>}
                    </div>
                </div>
                {GlobalState.ispopupdeleteCompanyShow?<PopupDeleteCompany/>:<></>}
                {GlobalState.ispopupdeleteMessageShow?<PopupDeleteMessage/>:<></>}
                {GlobalState.ispopupMicrosegmentShow?<PopupMicrosegment />:<></>}
                {GlobalState.ispopupinfoShow?<PopupInfo/>:<></>}
                {GlobalState.ispopupproductShow?<PopupAddProduct/>:<></>}
                {GlobalState.ispopupproductShow2?<PopupChangeProduct/>:<></>}
                {GlobalState.ispopupaddNewMessage?<PopupAddNewMessage/>:<></>}
                {GlobalState.ispopupsavenewmessage?<PopupSaveNewMessage {...{ setDatasegment, datasegment }} />:<></>}
                {GlobalState.ispopupdeleteProductShow?<PopupDeleteProduct id={GlobalState.comgaingdeleteid} name={GlobalState.comgaingdeletename} />:<></>}
                {/* <div className='scrollUp' onClick={()=> window.scrollTo({ top: 1, behavior: "smooth" })}>
                    <svg className='def_scrollUp'  width="47" height="42" viewBox="0 0 47 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M45.6158 19.5566C46.1281 20.4498 46.1281 21.5502 45.6158 22.4434L35.7997 39.5566C35.2874 40.4498 34.3406 41 33.316 41L13.684 41C12.6594 41 11.7126 40.4498 11.2003 39.5566L1.38423 22.4434C0.871921 21.5502 0.871921 20.4498 1.38423 19.5566L11.2003 2.44337C11.7126 1.55021 12.6594 0.999999 13.684 0.999999L33.316 0.999999C34.3406 0.999999 35.2874 1.55021 35.7997 2.44338L45.6158 19.5566Z" fill="#F5C538" stroke="#F5C538"/>
                        <ellipse cx="23.501" cy="25.4966" rx="1.97172" ry="1.99852" fill="#020203"/>
                        <ellipse cx="23.501" cy="25.4966" rx="1.97172" ry="1.99852" fill="black" fill-opacity="0.2"/>
                        <ellipse cx="23.501" cy="25.4966" rx="1.97172" ry="1.99852" fill="black" fill-opacity="0.2"/>
                        <path d="M15.6133 22.498L23.7392 14.5039L31.3871 22.498" stroke="#020203"/>
                        <path d="M15.6133 22.498L23.7392 14.5039L31.3871 22.498" stroke="black" stroke-opacity="0.2"/>
                        <path d="M15.6133 22.498L23.7392 14.5039L31.3871 22.498" stroke="black" stroke-opacity="0.2"/>
                    </svg>
                    <svg className='hov_scrollUp' width="47" height="41" viewBox="0 0 47 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M45.6158 19.0566C46.1281 19.9498 46.1281 21.0502 45.6158 21.9434L35.7997 39.0566C35.2874 39.9498 34.3406 40.5 33.316 40.5L13.684 40.5C12.6594 40.5 11.7126 39.9498 11.2003 39.0566L1.38423 21.9434C0.871921 21.0502 0.871921 19.9498 1.38423 19.0566L11.2003 1.94337C11.7126 1.05021 12.6594 0.499999 13.684 0.499999L33.316 0.499999C34.3406 0.499999 35.2874 1.05021 35.7997 1.94338L45.6158 19.0566Z" fill="#41A8B1" stroke="#41A8B1"/>
                        <ellipse cx="23.501" cy="24.9966" rx="1.97172" ry="1.99852" fill="#020203"/>
                        <ellipse cx="23.501" cy="24.9966" rx="1.97172" ry="1.99852" fill="black" fill-opacity="0.2"/>
                        <ellipse cx="23.501" cy="24.9966" rx="1.97172" ry="1.99852" fill="black" fill-opacity="0.2"/>
                        <path d="M15.6133 21.998L23.7392 14.0039L31.3871 21.998" stroke="#020203"/>
                        <path d="M15.6133 21.998L23.7392 14.0039L31.3871 21.998" stroke="black" stroke-opacity="0.2"/>
                        <path d="M15.6133 21.998L23.7392 14.0039L31.3871 21.998" stroke="black" stroke-opacity="0.2"/>
                    </svg>
                </div> */}
                </>
                :
                <div className="main-page">
                    <Beta/>
                </div>
            }
        </>
    );
})

export default Campaigns;

