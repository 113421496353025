import React, { useState, useContext } from "react";
import { AccountContext } from "./Account";
import { observer } from 'mobx-react'
import lang from "../../lang"
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import UserPool from './UserPool';
import GlobalState, {changeEmailUser} from "../../stores/GlobalState";


const ForgotPassword = observer(() => {
  const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
  const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)
 

  const onSubmit = (event) => {
    event.preventDefault();
    var userData = {Username: GlobalState.emailuser, Pool: UserPool};
    const user = new CognitoUser(userData);
    user.forgotPassword({onSuccess: (data) => {console.log('CodeDeliveryData from forgotPassword: ' + JSON.stringify(data)); location.href="/newpassword"}, onFailure: (err) => {console.log("Faced error", err);}})
  };

  return (
    <>
      <div className='central_img'></div>
      <div className='left_img'></div>
      <div className="login-container forgot">
          <form onSubmit={onSubmit}>
              <h1> Forgot <span>password?</span></h1>
              <p>Fear not. We'll send a verification code to your email so you can reset your password.</p>
              <label htmlFor="email">Username</label>
              <input
                  id="email"
                  className="auth-input"
                  type="text"
                  placeholder="Enter your username"
                  value={GlobalState.emailuser}
                  onChange={(event) => changeEmailUser(event.target.value)}
              />
             
              
              

             <button className={GlobalState.emailuser==""?"inactive":""} type="submit">Send code</button>
             <a href="/login" className="comebacklogin">Return to Sign In</a>
          </form>
      </div>
      <div className='right_img'></div>
    </>
    
  );
});

export default ForgotPassword;
